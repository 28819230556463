/* Home.css */
.home-container {
    background-color: #121212;
    color: #f0f0f0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
    padding-top: 0;

    /* Slim, greyish-transparent scrollbar styles */

        /* For WebKit browsers (Chrome, Safari) */
        &::-webkit-scrollbar {
            width: 2px; /* Even slimmer scrollbar width */
        }

        &::-webkit-scrollbar-track {
            background: transparent; /* Transparent track */
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(30, 30, 30, 0.2); /* Darker and more transparent grey thumb */
            border-radius: 3px; /* Slightly less rounded thumb */
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: rgba(50, 50, 50, 0.3); /* Slightly lighter and more opaque on hover */
        }

        /* For Firefox */
        scrollbar-width: thin; /* Make scrollbar thin in Firefox */
        scrollbar-color: rgba(30, 30, 30, 0.2) transparent; /* Thumb and track color for Firefox - darker and more transparent */
}

/* Hero Section Styles */
.hero-section {
    padding: 20px 20px 80px 20px;
    text-align: center;
    color: white;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to bottom, black, #ff9933);
    border-radius: 15px;
    overflow: hidden;
}

.hero-content-area {
    max-width: 1200px;
    width: 100%;
    padding: 10px 20px;
    background-color: transparent;
}

.hero-title {
    font-size: 4.5em;
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 20px;
}

.hero-description {
    font-size: 1.25em;
    color: #d0d0d0;
    line-height: 1.5;
    margin-bottom: 40px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.hero-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
}

.hero-download-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 30px;
    font-size: 1em;
    font-weight: 600;
    border-radius: 8px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    gap: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.windows-button {
    background-color: black;
    color: white;
    border: none;
}

.all-downloads-button {
    background-color: transparent;
    color: white;
    border: 1px solid white;
}

.windows-button:hover {
    background-color: #333;
}

.all-downloads-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.hero-download-button svg {
    height: 1em;
}

.hero-image-container {
    max-width: 80%;
    margin: 0 auto;
}

.hero-image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
}


/* Feature Sections Styles */
.feature-section {
    width: 100%;
    max-width: 1200px;
    padding: 80px 20px;
    box-sizing: border-box;
    margin: 0 auto;
    text-align: center;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(255, 153, 51, 0.2));
}

.feature-section:nth-child(even) {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(255, 153, 51, 0.2));
}

.feature-section-tab,
.feature-section-codebase {
    background: none;
}

.feature-text-area {
    margin-bottom: 40px;
}

.feature-title {
    font-size: 2.2em; /* Reduced font size */
    font-weight: 700;
    color: white;
    line-height: 1.2;
    margin-bottom: 20px;
}

.feature-description {
    font-size: 0.9em; /* Reduced font size */
    color: #d0d0d0;
    line-height: 1.6;
    max-width: 700px;
    margin: 0 auto;
}

.feature-image-background {
    background-color: transparent;
    border-radius: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;
}

.feature-gif-container {
    max-width: 70%; /* Reduced width of feature gif container */
    margin: 0 auto; /* Center the gif container */
}

.feature-gif {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
}


/* Card Sections Styles */
.card-section {
    width: 100%;
    max-width: 1200px;
    padding: 80px 20px;
    box-sizing: border-box;
    margin: 40px auto;
    text-align: center;
}

.card-section-title {
    font-size: 2.5em;
    font-weight: 700;
    color: white;
    margin-bottom: 40px;
}

.card-grid {
    display: grid;
    gap: 30px;
    margin-top: 20px;
    max-width: 800px; /* Reduced width for card grids */
    margin-left: auto;
    margin-right: auto;
}

/* 4-Card Grid */
.card-grid-4 {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for 4 cards */
}

/* 6-Card Grid */
.card-grid-6 {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for 6 cards - adjust if needed */
}


.card {
    background-color: #252525;
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease;
    border: 1px solid #444; /* Added greyish border */
}

.card:hover {
    transform: translateY(-5px);
}

.card-icon {
    font-size: 2.5em;
    color: #ff9933;
    margin-bottom: 20px;
}

.card-title {
    font-size: 1.5em;
    color: white;
    margin-bottom: 15px;
}

.card-description {
    font-size: 1em;
    color: #d0d0d0;
    line-height: 1.4;
    max-width: 300px;
    margin: 0 auto;
}


/* FAQ Section Styles */
.faq-section {
    width: 100%;
    max-width: 840px; /* Reduced width by 30% (1200 * 0.7 = 840) */
    padding: 80px 20px;
    box-sizing: border-box;
    margin: 40px auto;
    text-align: left; /* Left align FAQ content */
}

.faq-section-title {
    font-size: 2.5em;
    font-weight: 700;
    color: white;
    margin-bottom: 40px;
    text-align: center; /* Center FAQ section title */
}

.faq-list {
    margin-top: 20px;
    max-width: 800px; /* Reduced width for FAQ list */
    margin-left: auto;
    margin-right: auto;
}

.faq-item {
    margin-bottom: 15px;
    border-bottom: 1px solid #333; /* Separator between FAQs */
    padding-bottom: 15px;
}
.faq-item:last-child {
    border-bottom: none; /* Remove border from last FAQ item */
    padding-bottom: 0;
    margin-bottom: 0;
}


.faq-question {
    font-size: 1.2em;
    color: white;
    padding: 15px 20px;
    background-color: #252525;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
}

.faq-question:hover {
    background-color: #333;
}

.dropdown-arrow {
    font-size: 0.8em;
    color: #d0d0d0;
    transition: transform 0.3s ease;
}

.dropdown-arrow.active {
    transform: rotate(180deg);
}


.faq-answer {
    padding: 20px;
    background-color: #1a1a1a;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 1em;
    color: #d0d0d0;
    line-height: 1.6;
}


/* Basic adjustments for smaller screens */
@media (max-width: 960px) {
    .hero-title {
        font-size: 3.5em;
    }
    .hero-description {
        font-size: 1.1em;
    }
    .hero-buttons {
        flex-direction: column;
        align-items: center;
    }
    .hero-buttons a {
        width: 250px;
        text-align: center;
    }
    .feature-section {
        padding: 60px 20px;
    }
    .feature-title {
        font-size: 2.0em; /* Further reduce font size on smaller screens if needed */
    }
    .feature-description {
        font-size: 0.9em; /* Keep same reduced size or adjust slightly */
    }
    .feature-image-background {
        padding: 0px;
    }
    .feature-gif-container {
        max-width: 90%; /* Allow gif container to be wider on smaller screens */
    }
    .card-grid {
        max-width: 90%; /* Allow card grid to be wider on smaller screens */
    }
    .card-grid-4, .card-grid-6 {
        grid-template-columns: 1fr; /* Stack cards on smaller screens */
    }
    .card {
        padding: 20px; /* Less padding for cards on smaller screens */
    }
    .card-section, .faq-section {
        padding: 60px 20px; /* Adjust section padding on smaller screens */
    }
    .faq-section {
        max-width: 90%; /* Allow faq section to be wider on smaller screens */
    }
    .faq-list {
        max-width: 90%; /* Allow faq list to be wider on smaller screens */
    }
}