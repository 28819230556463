@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap');

/* Apply scrollbar styles to the root element for global effect */
:root {
    /* Slim, darker and more transparent scrollbar styles */

    /* For WebKit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
        width: 3px; /* Even slimmer scrollbar width */
    }

    &::-webkit-scrollbar-track {
        background: transparent; /* Transparent track */
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(30, 30, 30, 0.2); /* Darker and more transparent grey thumb */
        border-radius: 2px; /* Slightly less rounded thumb */
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(50, 50, 50, 0.3); /* Slightly lighter and more opaque on hover */
    }

    /* For Firefox */
    scrollbar-width: thin; /* Make scrollbar thin in Firefox */
    scrollbar-color: rgba(30, 30, 30, 0.2) transparent; /* Thumb and track color for Firefox - darker and more transparent */
}

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: black;
    box-sizing: border-box; /* Good practice */
  }

.app-header {
    background-color: black; /* Background color changed to black */
    padding: 20px;
    color: #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Space Grotesk', sans-serif; /* Apply Space Grotesk font */
}

.header-left, .header-right {
    display: flex;
    align-items: center;
}

.header-left {
    margin-right: 20px;
}

.logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
}

.app-logo {
    height: 40px;
    margin-right: 10px;
}

.neocu-text {
    font-size: 1.2em;
    color: white;
}

.main-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 25px;
}

.main-nav a {
    font-size: 1.1em;
    color: #f0f0f0;
    text-decoration: none;
    padding: 5px 0;
    border-bottom: 2px solid transparent;
    transition: border-bottom-color 0.3s ease;
}

.main-nav a:hover {
    border-bottom-color: #f0f0f0;
}

.header-right {
    display: flex;
    gap: 20px;
}

.sign-in-button {
    font-size: 1.1em;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 5px;
    border: none; /* Removed border for both buttons to simplify */
    background-color: transparent; /* Default transparent background */
    color: white; /* Default white text color */
}

.sign-in-button:hover {
    background-color: #333; /* Darker background on hover */
}

.sign-in-button {
    border: 1px solid #555; /* Added grey border back for Sign In button */
    color: white; /* Ensure white text for Sign In */
    background-color: transparent; /* Transparent background for Sign In */
}

.sign-in-button:hover {
    background-color: #555; /* Darker background on hover for Sign In */
}


.download-button {
    background-color: #ff914d; /* Orange background for Download button */
    color: black; /* Black text color for Download */
}

.download-button:hover {
    background-color: #ffb37a; /* Slightly lighter orange on hover */
    color: black; /* Keep text black on hover */
}


.download-icon {
    margin-right: 5px;
    font-size: 1em;
    color: black; /* Ensure icon is black for download button */
}