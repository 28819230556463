@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;700&display=swap');

/* General Header Styles */
.app-header {
  background-color: black;
  padding: 15px 30px; /* Adjusted padding */
  color: #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Space Grotesk', sans-serif;
  position: relative; /* Needed for potential absolute positioning inside */
  z-index: 1000; /* Ensure header is above page content */
}

.header-left, .header-right {
  display: flex;
  align-items: center;
}

.header-left {
  margin-right: 20px; /* Keep some space */
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.app-logo {
  height: 35px; /* Slightly smaller logo */
  margin-right: 8px;
}

.neocu-text {
  font-size: 1.1em; /* Slightly smaller text */
  color: white;
  font-weight: 700;
}

/* Desktop Navigation */
.main-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px; /* Slightly reduced gap */
}

.main-nav a {
  font-size: 1.0em; /* Slightly smaller font */
  color: #f0f0f0;
  text-decoration: none;
  padding: 5px 0;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.3s ease, color 0.3s ease; /* Added color transition */
}

.main-nav a:hover {
  border-bottom-color: #f0f0f0;
  color: #ff914d; /* Highlight color on hover */
}

/* Right Side Actions (General) */
.header-right {
    gap: 15px; /* Space between items on the right */
}

/* Download Button */
.header-right .download-button {
    font-size: 0.9em; /* Matched sign in button */
    color: black;
    text-decoration: none;
    padding: 10px 15px; /* Adjusted padding */
    border-radius: 5px;
    background-color: #ff914d;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px; /* Increased gap */
    font-weight: bold; /* Make text bold */
}

.download-button:hover {
  background-color: #e68244; /* Darker shade on hover */
}

.download-icon {
    margin-right: 5px;
    font-size: 1em;
}


/* Custom Sign In Button Styles */
.signin-button-custom {
  display: inline-block;
  padding: 10px 15px; /* Adjusted padding */
  font-size: 0.9em; /* Adjusted size */
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  background-color: black;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-weight: bold; /* Make text bold */
  font-family: 'Space Grotesk', sans-serif; /* Ensure font */
}

.signin-button-custom:hover {
  background-color: #333;
  color: #fff;
}


/* Profile Button and Dropdown Styles for Header (Desktop) */
.profile-container-header {
    position: relative;
    display: inline-block;
}

.profile-button-header {
    background-color: #333; /* Dark grey background */
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px; /* Consistent size */
    height: 38px; /* Consistent size */
    border-radius: 50%;
    padding: 0; /* Remove padding if using width/height */
    overflow: hidden; /* Ensure image fits */
}

.profile-button-header img.desktop-profile-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.profile-button-header svg {
    font-size: 1.5em; /* Adjusted icon size */
    color: #90D6FF;
}

.profile-initials-header {
    font-size: 1.2em; /* Adjusted initial size */
    font-weight: bold;
    color: white;
}


.profile-button-header:hover, .profile-button-header:focus {
    background-color: #555; /* Slightly lighter grey on hover */
}

.user-dropdown-header {
    position: absolute;
    top: calc(100% + 5px); /* Position below button with gap */
    right: 0;
    background-color: #171717; /* Darker dropdown background */
    border: 1px solid #444; /* Adjusted border color */
    border-radius: 8px;
    min-width: 160px; /* Ensure enough width */
    z-index: 1100; /* Above header content */
    box-shadow: 0 4px 12px rgba(0,0,0,0.3); /* Add shadow */
    overflow: hidden; /* Ensures border radius applies to children */
}

.user-dropdown-header .dropdown-item {
    padding: 12px 18px; /* Increased padding */
    cursor: pointer;
    font-size: 0.95em; /* Slightly larger font */
    color: #e0e0e0; /* Lighter text color */
    text-decoration: none;
    display: block;
    text-align: left;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.user-dropdown-header .dropdown-item:hover {
    background-color: #333;
    color: white;
}

/* Style the SignOutButton wrapper div */
.user-dropdown-header .dropdown-item.signout-dropdown-item {
     padding: 0; /* Remove padding from wrapper */
}

/* Style the button *inside* the SignOutButton */
.user-dropdown-header .dropdown-item.signout-dropdown-item button {
    background: none;
    color: #e0e0e0; /* Match item color */
    border: none;
    padding: 12px 18px; /* Apply padding here */
    font-size: 0.95em; /* Match item font size */
    cursor: pointer;
    text-align: left;
    width: 100%;
    display: block; /* Ensure it takes full width */
    font-family: 'Space Grotesk', sans-serif; /* Ensure font */
    transition: background-color 0.2s ease, color 0.2s ease;
}

.user-dropdown-header .dropdown-item.signout-dropdown-item button:hover {
    background-color: #333; /* Match item hover background */
    color: #ff6b6b; /* Reddish color on hover for sign out */
}

/* Hamburger Menu Styles */
.hamburger-menu {
    display: none; /* Hidden by default */
    background: none;
    border: none;
    color: white;
    font-size: 1.8em; /* Larger icon */
    cursor: pointer;
    padding: 5px; /* Add some padding for easier tapping */
    margin-left: 10px; /* Space from edge if needed */
}

/* Mobile Menu Overlay Styles */
.mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 10, 10, 0.98); /* Slightly transparent dark */
    z-index: 1500; /* Above header, below modal */
    display: flex;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Style overlay when mobile menu is open */
.mobile-menu-overlay:has(.mobile-menu-content) { /* Or use JS to add a class */
    opacity: 1;
    visibility: visible;
}


.mobile-menu-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Space between sections */
    width: 90%; /* Take up most of the width */
    max-width: 400px; /* Max width */
    padding: 60px 20px 40px; /* Top padding for close button space */
}

.mobile-menu-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: #aaa;
    font-size: 2em; /* Large close icon */
    cursor: pointer;
    transition: color 0.3s ease;
}
.mobile-menu-close:hover {
    color: white;
}

/* Mobile Navigation */
.mobile-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px; /* Space between links */
    width: 100%;
}

.mobile-nav a {
    font-size: 1.4em; /* Larger text for mobile */
    color: #f0f0f0;
    text-decoration: none;
    padding: 10px 0;
    transition: color 0.3s ease;
    display: block; /* Make link take full width for easier tapping */
    text-align: center;
}

.mobile-nav a:hover {
    color: #ff914d; /* Highlight color */
}

/* Mobile Actions (Sign in, Profile, Download) */
.mobile-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Space between action items */
    margin-top: 20px; /* Space above actions */
    width: 100%;
    border-top: 1px solid #444; /* Separator line */
    padding-top: 25px; /* Space after separator */
}

/* Adjust button styles for mobile menu */
.mobile-actions .signin-button-custom,
.mobile-actions .download-button {
    font-size: 1.2em; /* Larger font */
    padding: 12px 25px; /* Larger padding */
    width: 80%; /* Make buttons wider */
    max-width: 250px; /* Max width for buttons */
    text-align: center; /* Center text */
    justify-content: center; /* Center icon and text */
}
.mobile-actions .download-button {
    color: black; /* Ensure download button text is black */
}

/* Mobile Profile Section specific styles */
.mobile-profile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 100%;
    color: white;
}

.mobile-user-info {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 1.1em;
    margin-bottom: 10px; /* Space below user info */
}

.mobile-profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.mobile-profile-section .profile-initials-header {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #555;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4em;
}

.mobile-menu-item {
    font-size: 1.2em;
    color: #f0f0f0;
    cursor: pointer;
    padding: 8px 0;
    transition: color 0.3s ease;
    width: 100%;
    text-align: center;
}
.mobile-menu-item:hover {
    color: #ff914d;
}

.mobile-signout-item button {
    background: none;
    border: none;
    color: #ff6b6b; /* Sign out color */
    font-size: 1.2em;
    cursor: pointer;
    padding: 8px 0;
    font-family: 'Space Grotesk', sans-serif;
    transition: color 0.3s ease;
    width: 100%;
    text-align: center;
}
.mobile-signout-item button:hover {
    color: #ff3b3b; /* Darker red on hover */
}


/* Responsive Breakpoint */
@media (max-width: 880px) { /* Adjust breakpoint as needed */
    .desktop-only {
        display: none; /* Hide desktop nav and actions */
    }
    .mobile-only {
        display: flex; /* Show elements intended only for mobile (like hamburger container) */
    }
    .hamburger-menu {
        display: block; /* Show hamburger button */
    }
    .app-header {
        padding: 10px 15px; /* Less padding on mobile */
    }
    .neocu-text {
       font-size: 1.0em; /* Smaller brand text */
    }
    .app-logo {
        height: 30px; /* Smaller logo */
    }
}

/* Hide mobile-only elements on desktop */
@media (min-width: 881px) { /* One pixel above the max-width breakpoint */
    .mobile-only {
        display: none;
    }
}


/* User Profile Modal Styles (Unchanged, but ensure high z-index) */
.compute-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000; /* Highest z-index */
}

.compute-modal-content {
    background-color: #171717;
    /* padding: 15px; */ /* Padding is added to inner div now */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    color: white;
    width: 90%; /* Adjusted width */
    max-width: 500px; /* Adjusted max-width for profile */
    max-height: 90%; /* Adjusted max-height */
    overflow-y: auto;
    display: flex; /* Using flex to help center content */
    font-size: 0.9em; /* Base font size */
}

/* Ensure Clerk's component fits well */
.compute-modal-content .cl-userProfile-root {
   width: 100%;
   box-shadow: none; /* Remove Clerk's default shadow if desired */
}

.modal-close-button {
    position: absolute;
    top: 15px; /* Adjust position */
    right: 15px; /* Adjust position */
    background: none;
    border: none;
    color: #aaa; /* Grey close button */
    font-size: 1.5em; /* Adjust size */
    cursor: pointer;
    z-index: 10; /* Above modal content */
    transition: color 0.3s ease;
}

.modal-close-button:hover {
    color: #ff6b6b; /* Red hover */
}

/* Override default Clerk modal styles if they interfere */
/* These might not be needed if UserProfile is used directly */
.cl-modal.cl-card {
    max-width: 100%; /* Allow our container to control size */
    max-height: 100%;
    box-shadow: none;
    border: none;
}