/* Careers.css */

.careers-container {
    background-color: black;
    color: #f0f0f0;
    min-height: calc(100vh - 60px); /* Adjust based on header height */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content vertically */
    padding: 60px 20px; /* Adjusted padding top/bottom slightly */
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    text-align: center; /* Center align text */
    overflow-x: hidden;

    /* Slim scrollbar styles (no change needed here) */
    &::-webkit-scrollbar { width: 2px; }
    &::-webkit-scrollbar-track { background: transparent; }
    &::-webkit-scrollbar-thumb { background-color: rgba(30, 30, 30, 0.2); border-radius: 3px; }
    &::-webkit-scrollbar-thumb:hover { background-color: rgba(50, 50, 50, 0.3); }
    scrollbar-width: thin;
    scrollbar-color: rgba(30, 30, 30, 0.2) transparent;
}

.careers-content {
    max-width: 700px; /* Slightly adjusted max-width for card */
    width: 90%; /* Use percentage for better responsiveness */
    /* --- Card Styling --- */
    border: 1px solid white;
    padding: 40px; /* Inner padding for the card content */
    border-radius: 8px; /* Optional: rounded corners for the card */
    background-color: rgba(10, 10, 10, 0.3); /* Optional: Slight background tint for card */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    margin-bottom: 40px; /* Add space below the card if open positions are added */
}

.careers-title {
    /* font-size: 3.5em * 0.7 = 2.45em */
    font-size: 2.45em;
    font-weight: 700;
    color: white;
    margin-bottom: 20px; /* Adjusted margin */
    line-height: 1.2;
}

.careers-description {
    /* font-size: 1.2em * 0.7 = 0.84em */
    font-size: 0.84em;
    color: #d0d0d0;
    line-height: 1.6;
    margin-bottom: 30px; /* Adjusted margin */
    max-width: 100%; /* Allow description to fill card width */
    margin-left: auto;
    margin-right: auto;
}

.careers-contact {
    /* font-size: 1.1em * 0.7 = 0.77em */
    font-size: 0.77em;
    color: #c0c0c0;
    line-height: 1.6;
}

.careers-email-link {
    color: #ff9933;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s ease;
    /* Font size inherited from .careers-contact */
}

.careers-email-link:hover {
    color: #ffbf6f;
    text-decoration: underline;
}

/* --- Optional styling for open positions section --- */
/* --- Adjusted font sizes and adapted to sit below the card --- */
.open-positions {
    /* Removed margin-top, now relies on margin-bottom of .careers-content */
    padding-top: 30px; /* Reduced top padding */
    border-top: 1px solid #333;
    text-align: left;
    max-width: 600px;
    width: 90%; /* Use percentage */
    margin-left: auto;
    margin-right: auto;
    color: #d0d0d0; /* Set base color */
}

.open-positions h2 {
    /* font-size: 2em * 0.7 = 1.4em */
    font-size: 1.4em;
    color: white;
    margin-bottom: 15px; /* Adjusted margin */
    text-align: center;
}

.open-positions p {
     /* font-size: 1em * 0.7 = 0.7em */
     font-size: 0.7em;
     line-height: 1.6;
     margin-bottom: 15px; /* Adjusted margin */
     text-align: center;
}

.open-positions ul {
    list-style: disc;
    margin-left: 30px; /* Adjusted indent */
    padding-left: 0; /* Remove default padding */
}

.open-positions li {
    margin-bottom: 8px; /* Adjusted margin */
    /* font-size: 1.1em * 0.7 = 0.77em */
    font-size: 0.77em;
}


/* --- Responsive Adjustments --- */
@media (max-width: 768px) {
    .careers-content {
        padding: 30px; /* Reduce card padding on smaller screens */
    }

    .careers-title {
        /* font-size: 2.8em * 0.7 = 1.96em */
        font-size: 1.96em;
    }
    .careers-description {
        /* font-size: 1.1em * 0.7 = 0.77em */
        font-size: 0.77em;
    }
    .careers-contact {
        /* font-size: 1em * 0.7 = 0.7em */
        font-size: 0.7em;
    }
    .careers-container {
         padding: 40px 15px; /* Adjust container padding */
    }

    .open-positions h2 {
        /* font-size: 1.8em * 0.7 = 1.26em */
        font-size: 1.26em;
    }
     .open-positions ul {
        margin-left: 20px; /* Adjust indent */
    }
    .open-positions li {
        /* font-size: 1em * 0.7 = 0.7em */
        font-size: 0.7em;
    }
}

@media (max-width: 480px) {
     .careers-content {
        padding: 20px; /* Further reduce card padding */
        width: 95%; /* Allow card to take more width */
    }

    .careers-title {
        /* font-size: 2.2em * 0.7 = 1.54em */
        font-size: 1.54em;
        margin-bottom: 15px;
    }
    .careers-description {
        /* font-size: 1em * 0.7 = 0.7em */
        font-size: 0.7em;
        margin-bottom: 20px;
    }
    .careers-contact {
        /* font-size already 0.7em from previous breakpoint */
        font-size: 0.7em; /* Explicitly set */
    }
    .open-positions {
        width: 95%;
    }
    .open-positions h2 {
        font-size: 1.1em; /* Further reduce */
    }
    .open-positions p {
        font-size: 0.65em; /* Further reduce */
    }
     .open-positions li {
        font-size: 0.65em; /* Further reduce */
    }
}