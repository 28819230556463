/* Features.css */

.features-container {
  background-color: #121212;
  color: #f0f0f0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
  padding-top: 0;

  /* Slim scrollbar styles */
  &::-webkit-scrollbar { width: 2px; }
  &::-webkit-scrollbar-track { background: transparent; }
  &::-webkit-scrollbar-thumb { background-color: rgba(30, 30, 30, 0.2); border-radius: 3px; }
  &::-webkit-scrollbar-thumb:hover { background-color: rgba(50, 50, 50, 0.3); }
  scrollbar-width: thin;
  scrollbar-color: rgba(30, 30, 30, 0.2) transparent;
}

/* Hero Section Styles (Copied from Home.css/Enterprise.css) */
.hero-section {
  padding: 20px 20px 80px 20px;
  text-align: center;
  color: white;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to bottom, black, #ff9933); /* Or feature-specific gradient */
  border-radius: 15px;
  overflow: hidden;
}

.hero-content-area {
  max-width: 1200px;
  width: 100%;
  padding: 10px 20px;
  background-color: transparent;
}

.hero-title {
  font-size: 4.5em;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 20px;
}

.hero-description {
  font-size: 1.25em;
  color: #d0d0d0;
  line-height: 1.5;
  margin-bottom: 40px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.hero-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

.hero-download-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 30px;
  font-size: 1em;
  font-weight: 600;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  gap: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.windows-button {
  background-color: black;
  color: white;
  border: none;
}

.all-downloads-button {
  background-color: transparent;
  color: white;
  border: 1px solid white;
}

.windows-button:hover { background-color: #333; }
.all-downloads-button:hover { background-color: rgba(255, 255, 255, 0.1); }

.hero-image-container {
  max-width: 80%;
  margin: 0 auto;
}

.hero-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
}

/* Features Grid Section Styles */
.features-list-section {
  width: 100%;
  max-width: 1400px; /* Wider max-width for feature grid */
  padding: 80px 20px;
  box-sizing: border-box;
  margin: 40px auto;
  text-align: center;
}

.features-list-title {
  font-size: 2.8em; /* Slightly larger title */
  font-weight: 700;
  color: white;
  margin-bottom: 50px; /* More space below title */
}

.features-grid {
  display: grid;
  gap: 30px; /* Gap between cards */
  /* Responsive grid: Fits as many columns >= 280px as possible */
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  margin-top: 20px;
  width: 100%;
}

.feature-card {
  background-color: #252525;
  border-radius: 10px;
  padding: 30px;
  text-align: left; /* Align text left within cards */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #444;
  display: flex; /* Use flexbox for better alignment */
  flex-direction: column; /* Stack icon, title, description vertically */
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5); /* Enhance shadow on hover */
}

.feature-card-icon {
  font-size: 2.2em; /* Slightly smaller icon than home cards */
  color: #ff9933; /* Feature icon color */
  margin-bottom: 20px;
  width: fit-content; /* Prevent icon from stretching */
}

.feature-card-title {
  font-size: 1.4em;
  color: white;
  font-weight: 600;
  margin-bottom: 10px;
}

.feature-card-description {
  font-size: 0.95em; /* Standard description size */
  color: #d0d0d0;
  line-height: 1.5;
  flex-grow: 1; /* Allow description to take remaining space */
}

/* Responsive adjustments for Hero section (copied) */
@media (max-width: 960px) {
  .hero-title { font-size: 3.5em; }
  .hero-description { font-size: 1.1em; }
  .hero-buttons { flex-direction: column; align-items: center; }
  .hero-buttons a { width: 250px; text-align: center; }
  .hero-image-container { max-width: 90%; }

  .features-list-section { padding: 60px 20px; }
  .features-list-title { font-size: 2.4em; }
  /* Grid responsiveness is handled by auto-fit */
}

@media (max-width: 600px) {
  .hero-title { font-size: 2.8em; }
  /* Grid minimum width might need adjustment for very small screens */
  .features-grid {
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr)); /* Smaller min width */
      gap: 20px;
  }
  .feature-card { padding: 25px; }
  .feature-card-title { font-size: 1.3em; }
  .feature-card-description { font-size: 0.9em; }
}