/* src/blogs/article.css */

/* Apply base dark theme to body for overall background */
body {
    background-color: #121212; /* Very dark grey / Black */
    color: #e0e0e0; /* Default light text color */
    /* Consider adding default font-family if not set elsewhere */
    /* font-family: sans-serif; */
  }
  
  /* --- Base Article Styles (Dark Theme) --- */
  .article-container {
    max-width: 850px;
    margin: 40px auto;
    padding: 25px 40px; /* Slightly more padding */
    font-family: 'Georgia', serif;
    line-height: 1.75; /* Slightly increased line-height */
    color: #e0e0e0; /* Light grey text */
    background-color: #1e1e1e; /* Dark background for article */
    border-radius: 8px;
    border: 1px solid #555; /* Grey border */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3); /* Adjusted shadow for dark */
  }
  
  .article-title {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 2.9em; /* Slightly larger */
    margin-bottom: 15px;
    color: #ffffff; /* White title */
    line-height: 1.2;
    border-bottom: 3px solid #ff8c00; /* Orange accent border */
    padding-bottom: 20px;
    text-align: center; /* Center title */
  }
  
  .article-subtitle {
    font-size: 1.35em;
    color: #b0b0b0; /* Lighter grey subtitle */
    margin-bottom: 40px;
    font-style: italic;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    text-align: center; /* Center subtitle */
  }
  
  .article-content h2 {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1.9em;
    margin-top: 55px;
    margin-bottom: 25px;
    color: #f5f5f5; /* Off-white headings */
    border-left: 4px solid #ff8c00; /* Orange accent */
    padding-left: 15px;
    line-height: 1.3;
    /* Adding scroll-margin-top for TOC jumps */
    scroll-margin-top: 80px; /* Adjust as needed based on fixed header height */
  }
  
  .article-content h3 {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1.5em;
    margin-top: 40px;
    margin-bottom: 18px;
    color: #ffa500; /* Lighter Orange accent for H3 */
     /* Adding scroll-margin-top for TOC jumps */
    scroll-margin-top: 80px; /* Adjust as needed */
  }
  
  .article-content p {
    margin-bottom: 1.6em;
    text-align: justify;
    color: #dcdcdc; /* Ensure paragraphs have good contrast */
  }
  
  .article-content ul,
  .article-content ol {
    margin-left: 25px; /* Adjusted margin */
    margin-bottom: 1.6em;
    padding-left: 25px; /* Adjusted padding */
  }
  
  .article-content li {
    margin-bottom: 0.9em;
  }
  
  /* General links within article content */
  .article-content > a,
  .article-content p > a,
  .article-content li > a { /* More specific selectors */
    color: #ff8c00; /* Orange links */
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s ease, text-decoration 0.2s ease;
  }
  
  .article-content > a:hover,
  .article-content p > a:hover,
  .article-content li > a:hover {
    color: #ffa500; /* Lighter orange on hover */
    text-decoration: underline;
  }
  
  /* Inline Code */
  .article-content code {
    font-family: 'Courier New', Courier, monospace;
    background-color: #333; /* Dark background for inline code */
    padding: 3px 7px;
    border-radius: 4px;
    font-size: 0.9em;
    color: #ffcc80; /* Light orange text for inline code */
    border: 1px solid #444; /* Subtle border */
  }
  
  /* Code Blocks */
  .article-content pre {
    background-color: #161616; /* Very dark background for blocks */
    color: #d4d4d4; /* Light grey text (like VS Code dark) */
    padding: 18px 22px;
    border-radius: 6px;
    overflow-x: auto;
    font-family: 'Fira Code', 'Consolas', monospace;
    font-size: 0.9em;
    line-height: 1.6;
    margin: 25px 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    border: 1px solid #444; /* Darker border */
  }
  
  /* Reset specific styles for code inside pre */
  .article-content pre code {
    background-color: transparent;
    color: inherit;
    padding: 0;
    border-radius: 0;
    font-size: inherit;
    border: none; /* Remove border from inline style */
  }
  
  /* --- Table of Contents Specific Styles (Dark Theme) --- */
  .toc {
    background-color: #282828; /* Dark background for TOC */
    border: 1px solid #444; /* Dark border */
    padding: 20px 30px;
    margin-bottom: 50px;
    border-radius: 6px;
  }
  
  .toc h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.6em;
    color: #ededed; /* Light heading for TOC */
    border-left: none; /* Ensure no inherited border */
    padding-left: 0;
    border-bottom: 2px solid #555; /* Dark grey separator */
    padding-bottom: 12px;
     /* Prevent TOC H2 from causing scroll jump issues */
    scroll-margin-top: 0;
  }
  
  .toc ul {
    list-style-type: none;
    padding-left: 5px;
    margin-left: 0;
  }
  
  .toc li {
    margin-bottom: 12px;
  }
  
  .toc a {
    color: #ff8c00; /* Orange TOC links */
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s ease;
  }
  
  .toc a:hover {
    color: #ffa500; /* Lighter orange on hover */
    text-decoration: underline;
  }
  
  /* --- Varied Structure/Style Classes (Dark Theme Adaptation) --- */
  
  /* Style variation 1: Modern Dark (Orange Focus) */
  .article-style-modern .article-container {
    background-color: #1c1c1c; /* Slightly different dark */
    border-color: #ff8c00; /* Orange border */
  }
  .article-style-modern .article-title {
    /* text-align: center; */ /* Already centered */
    border-bottom: 4px solid #ffa500; /* Brighter orange title border */
    color: #ffffff;
  }
  .article-style-modern .article-subtitle {
     /* text-align: center; */ /* Already centered */
     color: #ffa500; /* Brighter orange subtitle */
  }
  .article-style-modern .article-content h2 {
    color: #ffa500; /* Brighter orange H2 */
    border-left-color: #ffa500;
  }
  .article-style-modern .article-content h3 {
    color: #ffc04d; /* Even lighter orange/yellow for H3 */
  }
  .article-style-modern .toc {
    background-color: #2f261f; /* Dark brown/orange tint background */
    border: 1px solid #cc7000; /* Darker orange border */
  }
  .article-style-modern .toc h2 {
      border-bottom-color: #7c4400;
      color: #ffd79c;
  }
  .article-style-modern .toc a { color: #ffae42; } /* Lighter orange links */
  .article-style-modern .toc a:hover { color: #ffcc80; }
  .article-style-modern code {
    color: #87ceeb; /* Sky blue text */
    background-color: #3a3a3a;
    border-color: #555;
  }
  .article-style-modern pre {
     background-color: #1a1a1a; /* Darker code block */
     border-color: #555;
     color: #b0e0e6; /* Powder blue text */
  }
  
  
  /* Style variation 2: Technical Dark (Subtle Accents) */
  .article-style-technical .article-container {
    background-color: #20252a; /* Dark blueish grey */
    border-color: #666; /* Mid-grey border */
  }
  .article-style-technical .article-title {
    font-family: 'Roboto Mono', monospace; /* Monospace Title */
    color: #e8e8e8;
    border-bottom-color: #666; /* Match border */
    font-size: 2.6em;
  }
  .article-style-technical .article-subtitle {
     font-family: 'Roboto Mono', monospace;
     color: #999; /* Greyer subtitle */
     font-size: 1.2em;
  }
  .article-style-technical .article-content h2 {
    font-family: 'Lato', sans-serif;
    color: #a0d2e8; /* Light Blue H2 */
    border-left: none;
    border-bottom: 2px solid #5a8faa; /* Blueish underline */
    padding-left: 0;
    padding-bottom: 8px;
  }
  .article-style-technical .article-content h3 {
    color: #ff8c00; /* Orange H3 stands out here */
    font-style: italic;
  }
  .article-style-technical .toc {
    border-left: 5px solid #ff8c00; /* Orange accent border */
    background-color: #2d2d2d; /* Dark grey TOC */
    border-top: 1px solid #444; /* Add other borders */
    border-right: 1px solid #444;
    border-bottom: 1px solid #444;
    padding: 18px 25px;
  }
  .article-style-technical .toc h2 {
    font-size: 1.5em;
    border-bottom: none;
    color: #ccc;
  }
  .article-style-technical .toc ul {
     list-style-type: decimal; /* Numbered TOC */
     padding-left: 25px; /* Indent numbers */
  }
  .article-style-technical .toc a {
    color: #ffae42; /* Lighter orange */
    font-weight: 400; /* Less bold */
  }
  .article-style-technical .toc a:hover { color: #ffcc80; }
  .article-style-technical code {
    background-color: #2a3138; /* Dark blueish */
    color: #ffa500; /* Orange inline code */
    border-color: #4b5661;
  }
  .article-style-technical pre {
    background-color: #282c34; /* Common dark editor bg */
    color: #abb2bf; /* Common dark editor text */
    border: 1px solid #555;
  }
  
  
  /* --- Blog Listing Page Specific Styles (Dark Theme) --- */
  .blogs-page-container {
    max-width: 1100px;
    margin: 40px auto; /* Increased top margin */
    padding: 20px;
  }
  
  .blogs-page-title {
    text-align: center;
    font-size: 2.8em; /* Larger title */
    margin-bottom: 15px;
    color: #ffffff; /* White */
  }
  
  .blogs-page-subtitle {
    text-align: center;
    font-size: 1.3em; /* Larger subtitle */
    color: #cccccc; /* Light grey */
    margin-bottom: 50px; /* Increased spacing */
  }
  
  .blog-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); /* Slightly larger min card width */
    gap: 35px; /* Increased gap */
  }
  
  .blog-card {
    background-color: #2a2a2a; /* Dark card background */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* Adjusted shadow */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid #444; /* Subtle border for cards */
  }
  
  .blog-card:hover {
    transform: translateY(-5px) scale(1.02); /* Add slight scale on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
  }
  
  /* Basic placeholder */
  .blog-card-image-placeholder {
      height: 180px;
      background-color: #444; /* Darker grey placeholder */
      display: flex;
      align-items: center;
      justify-content: center;
      color: #888; /* Lighter grey text */
      font-size: 0.9em;
      border-bottom: 1px solid #555; /* Separator line */
  }
  .blog-card-image-placeholder::before {
      content: 'Image Placeholder';
  }
  /* If using actual images: */
  /*
  .blog-card-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-bottom: 1px solid #555;
  }
  */
  
  .blog-card-content {
    padding: 25px; /* Increased padding */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .blog-card-title {
    font-size: 1.5em; /* Larger card title */
    margin-bottom: 12px;
    color: #f0f0f0; /* Light grey title */
    line-height: 1.3;
  }
  
  .blog-card-excerpt {
    font-size: 1em; /* Standard excerpt size */
    color: #b0b0b0; /* Lighter grey text */
    line-height: 1.6;
    flex-grow: 1;
    margin-bottom: 20px; /* More space before link */
  }
  
  .blog-card-read-more {
    display: inline-block;
    color: #ff8c00; /* Orange link */
    text-decoration: none;
    font-weight: bold;
    margin-top: auto;
    align-self: flex-start;
    transition: color 0.2s ease;
    padding: 8px 0; /* Add some padding for easier clicking */
  }
  
  .blog-card-read-more:hover {
    color: #ffa500; /* Lighter orange on hover */
  }
  
  /* Ensure smooth scrolling for TOC links */
  html {
    scroll-behavior: smooth;
  }