/* Pricing.css */
.pricing-container {
    background-color: black;
    color: #f0f0f0;
    min-height: 100vh;
    padding: 69px 23px; /* Increased by 15% */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Inter', sans-serif;
}

.pricing-header {
    margin-bottom: 46px; /* Increased by 15% */
    text-align: center;
}

.pricing-header h1 {
    color: white;
    font-size: 2.415em; /* Increased by 15% */
    font-weight: bold;
    margin-bottom: 9.2px; /* Increased by 15% */
}

.pricing-header p {
    font-size: 0.92em; /* Increased by 15% */
    color: #d0d0d0;
}

/* Pricing Tabs Styles */
.pricing-tabs {
    display: flex;
    margin-bottom: 34.5px; /* Increased by 15% */
    background-color: #1a1a1a;
    border-radius: 6.9px; /* Increased by 15% */
    padding: 4.6px; /* Increased by 15% */
}

.pricing-tab-button {
    background-color: transparent;
    color: #f0f0f0;
    border: none;
    padding: 9.2px 23px; /* Increased by 15% */
    font-size: 0.92em; /* Increased by 15% */
    font-weight: bold;
    border-radius: 5.75px; /* Increased by 15% */
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; /* Added color transition */
    white-space: nowrap; /* Prevent wrapping */
}

.pricing-tab-button.active {
    background-color: #333;
}

.pricing-tab-button:hover:not(.active):not(:disabled) { /* Added :not(:disabled) */
    background-color: #2a2a2a;
}

.pricing-tab-button:disabled { /* Style for disabled button */
    cursor: default;
    color: #666; /* Dim the text color */
}

.save-badge {
    color: #ff9933;
    font-size: 0.805em; /* Increased by 15% */
    margin-left: 4.6px; /* Increased by 15% */
}


/* Pricing Main Styles */
.pricing-main {
    display: flex;
    gap: 17.25px; /* Increased by 15% */
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 100%; /* Ensure it takes available width */
    align-items: stretch; /* Make cards equal height */
}

/* Pricing Plan Card Styles */
.pricing-plan {
    background-color: #121212;
    border-radius: 10.35px; /* Increased by 15% */
    padding: 24.15px; /* Increased by 15% */
    text-align: center;
    width: 257.6px; /* Increased by 15% */
    box-shadow: 0 4.6px 11.5px rgba(0, 0, 0, 0.5); /* Increased by 15% */
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    border: 1.15px solid #3a3a3a; /* Increased by 15% */
}

.pricing-plan.pro-plan {
    /* Optional: Subtle gradient for Pro plan */
    /* background-image: linear-gradient(to bottom, transparent 70%, rgba(255, 153, 51, 0.15) 100%); */
}
.pricing-plan.business-plan {
     /* Optional: Add specific style for business plan if desired */
     border-color: #555; /* Example: slightly different border */
}


.pricing-plan:hover {
    transform: translateY(-3.45px); /* Increased by 15% */
}

.plan-name {
    color: white;
    font-size: 1.495em; /* Increased by 15% */
    font-weight: bold;
    margin-bottom: 8.05px; /* Increased by 15% */
    text-align: center; /* Ensure name is centered */
}

.price-area {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 16.1px; /* Increased by 15% */
    border-bottom: 1.15px solid #555; /* Increased by 15% */
    padding-bottom: 11.5px; /* Increased by 15% */
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    min-height: 3em; /* Ensure space even if no period */
}


.pricing-plan .price {
    font-size: 2.3em; /* Increased by 15% */
    color: white;
    font-weight: bold;
    line-height: 1;
    text-align: center; /* Ensure price is centered */
}

.pricing-plan .period {
    font-size: 0.805em; /* Increased by 15% */
    color: #aaa;
    margin-left: 4.6px; /* Increased by 15% */
    align-self: flex-end; /* Keep alignment */
    margin-bottom: 0.23em; /* Increased by 15% */
    line-height: 1.2; /* Adjust line height if needed */
    white-space: nowrap; /* Prevent wrapping */
}


.plan-details {
    font-size: 0.805em; /* Increased by 15% */
    color: #d0d0d0;
    margin-bottom: 19.55px; /* Increased by 15% */
    text-align: center; /* Ensure details are centered */
    min-height: 1.5em; /* Ensure consistent spacing */
}

.features-list {
    list-style: none;
    padding: 0;
    margin-bottom: 24.15px; /* Increased by 15% */
    text-align: left; /* Align feature text to the left */
    width: 100%;
    flex-grow: 1; /* Allows list to expand and push button down */
}

.features-list li {
    margin-bottom: 8.05px; /* Increased by 15% */
    display: flex;
    align-items: flex-start; /* Align icon with first line of text */
    font-size: 0.805em; /* Increased by 15% */
    color: #f0f0f0;
    line-height: 1.4; /* Improve readability */
}

.check-icon {
    color: #ff9933; /* Orange check */
    margin-right: 8.05px; /* Increased by 15% */
    font-size: 0.8em; /* Slightly larger for visibility */
    min-width: 1.2em; /* Ensure space */
    text-align: center;
    flex-shrink: 0; /* Prevent icon from shrinking */
    margin-top: 0.15em; /* Align icon slightly lower if needed */
}

.cross-icon {
    color: #666; /* Grey cross */
    margin-right: 8.05px; /* Match check-icon spacing */
    font-size: 1em; /* Adjust size as needed */
    font-weight: bold;
    min-width: 1.2em; /* Match check-icon width */
    text-align: center;
    display: inline-block; /* Ensure alignment */
    line-height: 1; /* Align vertically */
    flex-shrink: 0; /* Prevent icon from shrinking */
    margin-top: 0.05em; /* Adjust vertical alignment slightly */
}


/* Plan Buttons Styles */
.plan-button {
    color: white;
    border: none;
    padding: 9.2px 24.15px; /* Increased by 15% */
    border-radius: 6.9px; /* Increased by 15% */
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
    font-size: 0.805em; /* Increased by 15% */
    width: calc(100% - 16.1px); /* Adjusted width and padding increase */
    max-width: 225.4px; /* Increased by 15% */
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    display: block; /* Changed from inline-block */
    text-align: center;
    /* margin-top: auto; - Removed, handled by wrapper div/<a> in JSX */
}

/* Ensure the anchor tag itself doesn't add extra spacing or change layout */
.pricing-plan a {
    display: block; /* Make the link take up block space */
    margin-top: auto; /* Push the link (containing the button) to the bottom */
    text-decoration: none; /* Remove underline */
    width: 100%; /* Make anchor take full width of its container */
    display: flex; /* Use flex to center the button inside */
    justify-content: center; /* Center button horizontally */
}
/* Apply margin-top to the wrapper div for non-link buttons */
.pricing-plan > div[style*="margin-top: auto"] {
     margin-top: auto;
}


.get-started-button {
    background-color: #ff9933;
    color: black;
}

.get-started-button:hover {
    background-color: #ffb37a;
}

.download-button {
    background-color: white;
    color: black;
}

.download-button:hover {
    background-color: #f0f0f0;
}

/* Removed .others-button styles as it's not used */


/* FAQ Section Styles */
.faq-section {
    width: 100%;
    max-width: 1200px; /* Match pricing main max-width */
    padding: 69px 23px; /* Increased by 15% */
    box-sizing: border-box;
    margin: 46px auto 0; /* Increased by 15% */
    text-align: left;
}

.faq-section-title {
    font-size: 2.3em; /* Increased by 15% */
    font-weight: 700;
    color: white;
    margin-bottom: 34.5px; /* Increased by 15% */
    text-align: center;
}

.faq-list {
    margin-top: 16.1px; /* Increased by 15% */
    max-width: 800px; /* Limit width for better readability */
    margin-left: auto;
    margin-right: auto;
}

.faq-item {
    margin-bottom: 11.5px; /* Increased by 15% */
    border-bottom: 1.15px solid #333; /* Increased by 15% */
    padding-bottom: 11.5px; /* Increased by 15% */
}

.faq-item:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.faq-question {
    font-size: 0.9775em; /* Increased by 15% */
    color: white;
    padding: 11.5px 16.1px; /* Increased by 15% */
    background-color: #252525;
    border-radius: 4.6px; /* Increased by 15% */
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
}

.faq-question:hover {
    background-color: #333;
}

.dropdown-arrow {
    font-size: 0.8em; /* Adjusted size */
    color: #d0d0d0;
    transition: transform 0.3s ease;
    margin-left: 10px; /* Add some space */
}

.dropdown-arrow.active {
    transform: rotate(180deg);
}

.faq-answer {
    padding: 16.1px; /* Increased by 15% */
    background-color: #1a1a1a;
    border-radius: 4.6px; /* Increased by 15% */
    margin-top: 8.05px; /* Increased by 15% */
    font-size: 0.86em; /* Slightly larger for readability */
    color: #d0d0d0;
    line-height: 1.6;
}


/* Responsive adjustments */
@media (max-width: 960px) {
    .pricing-header h1 {
        font-size: 2.3em;
    }
    .pricing-header p {
        font-size: 1em;
    }
    .pricing-main {
        /* Keep flex-direction: row; allow wrapping */
        /* If stacking is preferred uncomment below */
        /* flex-direction: column; */
        gap: 23px;
        align-items: center; /* Align items center when stacking */
    }
    .pricing-plan {
         /* Adjust width slightly more flexibly */
        width: clamp(250px, 45%, 402.5px); /* Min width, preferred width %, max width */
        margin-bottom: 17.25px;
    }
     /* Stack tabs earlier if needed */
    .pricing-tabs {
        flex-direction: column;
        align-items: stretch; /* Make tabs full width */
        width: 90%; /* Limit width */
        max-width: 402.5px; /* Match card max width */
        margin-bottom: 23px;
    }
    .pricing-tab-button {
        width: 100%;
        margin-bottom: 4.6px;
        text-align: center;
        box-sizing: border-box; /* Include padding in width */
    }
    .pricing-tab-button:last-child {
        margin-bottom: 0;
    }

    .faq-section, .pricing-container {
        padding: 57.5px 15px; /* Slightly reduce horizontal padding on small screens */
    }
    .faq-list {
        width: 100%; /* Use full width on smaller screens */
        padding: 0 10px; /* Add slight padding */
        box-sizing: border-box;
    }
}

@media (max-width: 600px) {
     .pricing-header h1 {
        font-size: 2em; /* Further reduce heading size */
    }
    .pricing-header p {
        font-size: 0.9em;
    }
    /* Ensure cards stack vertically */
    .pricing-main {
        flex-direction: column;
        align-items: center;
    }
     .pricing-plan {
        width: 95%; /* Make cards take most of the width */
        max-width: 400px; /* Optional max-width */
    }
     .pricing-tabs {
         width: 95%; /* Match card width */
         max-width: 400px; /* Optional max-width */
     }

     /* --- NEW: Feature list columns on mobile --- */
    .features-list {
        column-count: 2;
        column-gap: 15px; /* Adjust gap as needed */
    }
    .features-list li {
         /* Try to prevent list items from breaking across columns */
         break-inside: avoid-column;
         -webkit-column-break-inside: avoid; /* Safari/Chrome */
         page-break-inside: avoid; /* Firefox */
    }
     /* --- End Feature list columns --- */

    .faq-section-title {
        font-size: 1.8em; /* Reduce FAQ title size */
    }
    .faq-question {
        font-size: 0.9em;
    }
    .faq-answer {
         font-size: 0.8em;
    }
}